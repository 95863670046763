var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Routes } from "@/constants";
import { useRedirect } from "@/hooks";
import { api, getOrganization, getAllPortfolios, getUser, removeAuthTokenFromApi, getAuthorizedPortfolios, getInvestorSettings, getInvestments, valid_investment_statuses, getInvestment, } from "@/services/api";
import { getEligibilityTest } from "@/services/api/api/eligibilityTest";
import { saveAuthToken, removeAuthToken } from "@/services/auth";
import { asyncNoop, noop } from "@/utils";
import { WaveStatus, } from "./types";
var Context = createContext({
    user: null,
    organization: null,
    investorColors: null,
    investorSettings: null,
    investment: null,
    token: null,
    isUserLogged: false,
    isUserOnboarded: false,
    organizationId: null,
    waveStatus: WaveStatus.UNKNOWN,
    createSession: noop,
    destroySession: noop,
    setUserPreferences: noop,
    setWaveStatus: noop,
    preferences: {
        currency: null,
    },
    refetchCurrentUser: asyncNoop,
    refetchOrganization: asyncNoop,
    fetchAllPortfolios: asyncNoop,
    refetchInvestorData: asyncNoop,
    fetchAuthorizedPortfolios: asyncNoop,
    fetchInvestment: asyncNoop,
    authorizedPortfolios: [],
    permissions: [],
    portfolios: [],
});
var defaultPermissions = [];
function UserProvider(props) {
    var _this = this;
    var initialState = props.initialState, rest = __rest(props, ["initialState"]);
    var _a = useState(initialState.token), token = _a[0], setToken = _a[1];
    var _b = useState(initialState.user), user = _b[0], setUser = _b[1];
    var _c = useState(initialState.preferences), preferences = _c[0], setPreferences = _c[1];
    var _d = useState(WaveStatus.UNKNOWN), waveStatus = _d[0], setWaveStatusFlag = _d[1];
    var _e = useState(initialState.organization), organization = _e[0], setOrganization = _e[1];
    var _f = useState(initialState.investorColors), investorColors = _f[0], setInvestorColors = _f[1];
    var _g = useState(initialState.investorSettings), investorSettings = _g[0], setInvestorSettings = _g[1];
    var _h = useState(null), investment = _h[0], setInvestment = _h[1];
    var _j = useState([]), portfolios = _j[0], setAllPortfolios = _j[1];
    var _k = useState([]), authorizedPortfolios = _k[0], setAuthorizedPortfolios = _k[1];
    var redirectTo = useRedirect().redirectTo;
    var isUserLogged = !!(user === null || user === void 0 ? void 0 : user.email);
    var portfolioId = JSON.parse(localStorage.getItem("portfolio_id"));
    var organizationId = (user === null || user === void 0 ? void 0 : user.organizationId) || null;
    var permissions = useMemo(function () { return (user === null || user === void 0 ? void 0 : user.permissions) || defaultPermissions; }, [user === null || user === void 0 ? void 0 : user.permissions]);
    var userIsMainCoFounder = useMemo(function () { return permissions.includes("main_co_founder"); }, [permissions]);
    var eligibilityTest = useQuery(["eligibilityTest", organizationId, portfolioId, userIsMainCoFounder], function () {
        return organizationId && userIsMainCoFounder
            ? getEligibilityTest(organizationId, portfolioId)
            : undefined;
    }).data;
    var isUserOnboarded = !!((user === null || user === void 0 ? void 0 : user.firstName) &&
        organizationId &&
        (!userIsMainCoFounder || (organization === null || organization === void 0 ? void 0 : organization.investmentExists) ||
            typeof (eligibilityTest === null || eligibilityTest === void 0 ? void 0 : eligibilityTest.result) === "boolean"));
    var _l = useState(false), intercomBooted = _l[0], setIntercomBooted = _l[1];
    var setIntercomUser = useCallback(function (user_, company) {
        var _a;
        var data = {
            api_base: "https://api-iam.intercom.io",
            // @ts-ignore
            app_id: window.Intercom.workspace_id,
        };
        if (user_) {
            data.name = user_.firstName + " " + user_.lastName;
            data.email = user_.email;
            data.phone = user_.phone;
            data.user_hash = user_.userHash;
            data.created_at = user_.createdAt;
        }
        if (company) {
            // @ts-ignore
            data.company = {
                id: company.id,
                name: company.name,
                created_at: company.createdAt,
                industry: (_a = company.industry) === null || _a === void 0 ? void 0 : _a.name,
            };
        }
        // @ts-ignore
        window.Intercom(intercomBooted ? "update" : "boot", data);
        setIntercomBooted(true);
    }, [intercomBooted]);
    var shutDownIntercom = useCallback(function () {
        // @ts-ignore
        window.Intercom("shutdown");
        setIntercomBooted(false);
        setIntercomUser(null, null);
    }, [setIntercomUser]);
    var createSession = useCallback(function (tokenData, userData, userOrganization) {
        setUser(userData);
        setOrganization(userOrganization);
        setToken(tokenData);
        saveAuthToken(tokenData);
    }, []);
    var destroySession = useCallback(function () {
        setUser(null);
        setOrganization(null);
        setToken(null);
        removeAuthToken();
        shutDownIntercom();
    }, [shutDownIntercom]);
    var setUserPreferences = useCallback(function (preferencesData) {
        setPreferences(preferencesData);
    }, [setPreferences]);
    var setWaveStatus = useCallback(function (status) {
        setWaveStatusFlag(status);
    }, [setWaveStatusFlag]);
    var refetchCurrentUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var currentUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getUser()];
                case 1:
                    currentUser = _a.sent();
                    setUser(currentUser);
                    return [2 /*return*/];
            }
        });
    }); };
    var refetchOrganization = function () { return __awaiter(_this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getOrganization()];
                case 1:
                    data = _a.sent();
                    setOrganization(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var refetchInvestorData = function (params) { return __awaiter(_this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getInvestorSettings(params)];
                case 1:
                    data = _a.sent();
                    setInvestorColors(data.colors);
                    setInvestorSettings(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchAllPortfolios = function (ownerId) { return __awaiter(_this, void 0, void 0, function () {
        var portfoliosData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAllPortfolios(ownerId)];
                case 1:
                    portfoliosData = _a.sent();
                    setAllPortfolios(portfoliosData);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchAuthorizedPortfolios = function () { return __awaiter(_this, void 0, void 0, function () {
        var portfoliosData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthorizedPortfolios()];
                case 1:
                    portfoliosData = _a.sent();
                    setAuthorizedPortfolios(portfoliosData);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchInvestment = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var investmentDetailsData, validInvestments, activeInvestment;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 3];
                    return [4 /*yield*/, getInvestments({ organizationId: organizationId })];
                case 1:
                    investmentDetailsData = _a.sent();
                    validInvestments = investmentDetailsData.filter(function (validInvestment) {
                        return valid_investment_statuses.includes(validInvestment.status);
                    });
                    if (!validInvestments[0]) return [3 /*break*/, 3];
                    return [4 /*yield*/, getInvestment({ id: validInvestments[0].id })];
                case 2:
                    activeInvestment = _a.sent();
                    setInvestment(activeInvestment);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [setInvestment, organizationId]);
    useEffect(function () {
        api.interceptors.response.use(undefined, function (error) {
            if (error.response.status === 403) {
                removeAuthTokenFromApi();
                destroySession();
                redirectTo(Routes.LOGIN);
            }
            return Promise.reject(error);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(function () {
        setIntercomUser(user, organization);
    }, [setIntercomUser, user, organization]);
    return (React.createElement(Context.Provider, __assign({}, rest, { value: {
            token: token,
            user: user,
            organization: organization,
            investorColors: investorColors,
            investorSettings: investorSettings,
            isUserLogged: isUserLogged,
            isUserOnboarded: isUserOnboarded,
            organizationId: organizationId,
            waveStatus: waveStatus,
            createSession: createSession,
            destroySession: destroySession,
            investment: investment,
            fetchInvestment: fetchInvestment,
            preferences: preferences,
            portfolios: portfolios,
            authorizedPortfolios: authorizedPortfolios,
            setUserPreferences: setUserPreferences,
            setWaveStatus: setWaveStatus,
            refetchCurrentUser: refetchCurrentUser,
            refetchOrganization: refetchOrganization,
            fetchAllPortfolios: fetchAllPortfolios,
            refetchInvestorData: refetchInvestorData,
            fetchAuthorizedPortfolios: fetchAuthorizedPortfolios,
            permissions: permissions,
        } })));
}
export * from "./types";
export function useUserContext() {
    return useContext(Context);
}
export default UserProvider;
