import { UNKNOWN_INDICATOR } from "@/constants";
import { getModifiedCurrencyOutput } from "./currency";
import { roundToTwoDecimal, valuesPercentageIncrease } from "./number";
export function displayKpiValue(kpiValue, type, rate, currencyCode) {
    if (type === "percentage") {
        if (kpiValue === UNKNOWN_INDICATOR) {
            return UNKNOWN_INDICATOR;
        }
        return roundToTwoDecimal(Number(kpiValue)) + "%";
    }
    if (type === "money") {
        var _a = kpiValue, amount = _a.amount, currency = _a.currency;
        if (amount === UNKNOWN_INDICATOR || currency === UNKNOWN_INDICATOR) {
            return UNKNOWN_INDICATOR;
        }
        if (rate) {
            return getModifiedCurrencyOutput(amount * rate, currencyCode);
        }
        return getModifiedCurrencyOutput(amount, currencyCode);
    }
    if (type === "months") {
        if (kpiValue === UNKNOWN_INDICATOR) {
            return UNKNOWN_INDICATOR;
        }
        return kpiValue + " mo";
    }
    if (type === "ratio") {
        return "" + kpiValue;
    }
    return UNKNOWN_INDICATOR;
}
export function displayKpiLabel(kpiValue, type) {
    if (type === "money") {
        var _a = kpiValue, amount = _a.amount, currency = _a.currency;
        if (amount === UNKNOWN_INDICATOR || currency === UNKNOWN_INDICATOR) {
            return "";
        }
        return "(" + currency + ")";
    }
    return "";
}
export function displayKpiRelativeChange(type, value, valueToCompare) {
    var _a, _b;
    if (!value || !valueToCompare) {
        return null;
    }
    if (value === UNKNOWN_INDICATOR || valueToCompare === UNKNOWN_INDICATOR) {
        return null;
    }
    if (type === "money") {
        var amount = (_a = value) === null || _a === void 0 ? void 0 : _a.amount;
        var amountToCompare = (_b = valueToCompare) === null || _b === void 0 ? void 0 : _b.amount;
        if (!amount || !amountToCompare) {
            return null;
        }
        if (amount === UNKNOWN_INDICATOR || amountToCompare === UNKNOWN_INDICATOR) {
            return null;
        }
        return valuesPercentageIncrease(amountToCompare, amount);
    }
    return valuesPercentageIncrease(valueToCompare, value);
}
export var translateKpiItemName = function (name) {
    switch (name) {
        case "burn rate":
            return "Burn Rate";
        case "revenues":
            return "LTM Revenues";
        case "liquidity":
        case "liquidity ratio":
            return "Liquidity Ratio";
        case "runway":
            return "Runway";
        case "receivables":
            return "Accounts Receivable";
        default:
            return name;
    }
};
