var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle } from "styled-components";
export var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\na {\n  color:  ", ";\n  text-decoration: none;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n* {\n  font-family: 'Montserrat';\n}\n"], ["\na {\n  color:  ", ";\n  text-decoration: none;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n* {\n  font-family: 'Montserrat';\n}\n"])), function (_a) {
    var investorColors = _a.investorColors;
    return "" + (investorColors === null || investorColors === void 0 ? void 0 : investorColors["link-color"]);
});
var templateObject_1;
