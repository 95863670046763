import { useEffect, useMemo, useState } from "react";
var timeProcessor = function (timer) {
    return timer.toString().padStart(2, "0");
};
var calculateMinutes = function (timer) { return timeProcessor(Math.floor(timer / 60)); };
var calculateSeconds = function (timer) { return timeProcessor(timer % 60); };
export function useCountdown(time) {
    var _a = useState(typeof time === "number" ? calculateMinutes(time) : ""), minutes = _a[0], setMinutes = _a[1];
    var _b = useState(typeof time === "number" ? calculateSeconds(time) : ""), seconds = _b[0], setSeconds = _b[1];
    var _c = useState(null), timer = _c[0], setTimer = _c[1];
    var _d = useState(null), finishTime = _d[0], setFinishTime = _d[1];
    var displayCountdown = useMemo(function () { return Number(minutes) >= 0 && Number(seconds) >= 0; }, [
        minutes,
        seconds,
    ]);
    useEffect(function () {
        if (typeof time === "number" && timer === null) {
            var serverFinishTime = Date.now() + time * 1000;
            setFinishTime(serverFinishTime);
            setTimer(time);
        }
    }, [time, timer]);
    useEffect(function () {
        if (timer && timer >= 0) {
            var timerId_1 = window.setInterval(function () {
                var newTimer = Math.round(((finishTime || 0) - Date.now()) / 1000);
                setTimer(newTimer);
                setMinutes(calculateMinutes(newTimer));
                setSeconds(calculateSeconds(newTimer));
            }, 200);
            return function () { return window.clearInterval(timerId_1); };
        }
    }, [timer, finishTime]);
    return {
        displayCountdown: displayCountdown,
        minutes: minutes,
        seconds: seconds,
        secondsLeft: timer,
    };
}
