import { useCallback, useEffect, useState } from "react";
export function useToggle(initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    useEffect(function () { return setValue(initialValue); }, [initialValue]);
    var toggle = useCallback(function () {
        setValue(function (prevValue) { return !prevValue; });
    }, []);
    return [value, toggle, setValue];
}
