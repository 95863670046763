import { isYearLeap } from "@/utils";
export var defaultDigitalIdentityStats = {
    currentMultiple: 0,
    currentPrice: 0,
    estimatedRepurchaseDate: "",
    repaymentScore: 0,
    reportingScore: 0,
    repurchasingProgress: 0,
    repurchaseSum: { currency: "", amount: "" },
    repurchasedSum: { currency: "", amount: "" },
    repurchasePeriodDates: [],
    investmentSum: { currency: "", amount: "" },
    totalRepurchasePeriods: [],
    monthsSinceInvestmentStart: 0,
};
export function getDates(index, year) {
    var dates = {
        // 1 month - option number
        "1-1": {
            startDate: year + "-01-01",
            endDate: year + "-01-31",
        },
        "1-2": {
            startDate: year + "-02-01",
            endDate: isYearLeap(year) ? year + "-02-29" : year + "-02-28",
        },
        "1-3": {
            startDate: year + "-03-01",
            endDate: year + "-03-31",
        },
        "1-4": {
            startDate: year + "-04-01",
            endDate: year + "-04-30",
        },
        "1-5": {
            startDate: year + "-05-01",
            endDate: year + "-05-31",
        },
        "1-6": {
            startDate: year + "-06-01",
            endDate: year + "-06-30",
        },
        "1-7": {
            startDate: year + "-07-01",
            endDate: year + "-07-31",
        },
        "1-8": {
            startDate: year + "-08-01",
            endDate: year + "-08-31",
        },
        "1-9": {
            startDate: year + "-09-01",
            endDate: year + "-09-30",
        },
        "1-10": {
            startDate: year + "-10-01",
            endDate: year + "-10-31",
        },
        "1-11": {
            startDate: year + "-11-01",
            endDate: year + "-11-30",
        },
        "1-12": {
            startDate: year + "-12-01",
            endDate: year + "-12-31",
        },
        // 3 months - option number
        "2-1": {
            startDate: year + "-01-01",
            endDate: year + "-03-31",
        },
        "2-2": {
            startDate: year + "-04-01",
            endDate: year + "-06-30",
        },
        "2-3": {
            startDate: year + "-07-01",
            endDate: year + "-09-30",
        },
        "2-4": {
            startDate: year + "-10-01",
            endDate: year + "-12-31",
        },
        // 6 months - option number
        "3-1": {
            startDate: year + "-01-01",
            endDate: year + "-06-30",
        },
        "3-2": {
            startDate: year + "-07-01",
            endDate: year + "-12-31",
        },
        // 12 months - option number
        "4-1": {
            startDate: year + "-01-01",
            endDate: year + "-12-31",
        },
    };
    return dates[index];
}
