import { useMemo, useState } from "react";
function paginate(array, perPage) {
    return array.reduce(function (acc, val, i) {
        var idx = Math.floor(i / perPage);
        var page = acc[idx] || (acc[idx] = []);
        page.push(val);
        return acc;
    }, []);
}
export function usePagination(array, perPage) {
    var _a = useState(0), page = _a[0], setPage = _a[1];
    var limit = Math.ceil(array.length / perPage) - 1;
    var isFirstPage = page === 0;
    var isLastPage = page === limit;
    var goNextPage = function () {
        var incrementedPage = page + 1;
        var currentPage = incrementedPage >= limit ? limit : incrementedPage;
        setPage(currentPage);
    };
    var goBackPage = function () {
        var decrementedPage = page - 1;
        var currentPage = decrementedPage <= 0 ? 0 : decrementedPage;
        setPage(currentPage);
    };
    var paginatedArray = useMemo(function () { return paginate(array, perPage)[page]; }, [array, page, perPage]);
    return {
        goBackPage: goBackPage,
        goNextPage: goNextPage,
        isFirstPage: isFirstPage,
        isLastPage: isLastPage,
        limit: limit,
        page: page,
        paginatedArray: paginatedArray,
    };
}
