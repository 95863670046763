import { useEffect } from "react";
export function useDisableScroll(disable) {
    useEffect(function () {
        if (disable) {
            document.body.style.overflow = "hidden";
        }
        return function () {
            document.body.style.overflow = "unset";
        };
    }, [disable]);
}
