import React, { memo } from "react";
import { useUserContext } from "@/contexts";
import { BrandContentImage, BrandContentInfo, Wrapper } from "./styles";
function BrandContent() {
    var _a = useUserContext(), investorColors = _a.investorColors, investorSettings = _a.investorSettings;
    return (React.createElement(Wrapper, { colors: investorColors },
        React.createElement(BrandContentInfo, { colors: investorColors }, investorSettings === null || investorSettings === void 0 ? void 0 : investorSettings.brand_text),
        React.createElement(BrandContentImage, { src: investorSettings === null || investorSettings === void 0 ? void 0 : investorSettings.brand_logo_url })));
}
export default memo(BrandContent);
