var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { colors } from "@/constants";
import { media } from "@/utils";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n\n  ", " {\n    flex-direction: column;\n  }\n"], ["\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n\n  ", " {\n    flex-direction: column;\n  }\n"])), media.max(414));
export var ErrorWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: Montserrat;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  margin-left: 2rem;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  font-family: Montserrat;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  margin-left: 2rem;\n  flex: 1;\n  flex-direction: column;\n"])));
export var Heading = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 2rem;\n"], ["\n  margin-bottom: 2rem;\n"])));
export var ErrorDetails = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 12px;\n  color: ", ";\n"], ["\n  font-size: 12px;\n  color: ", ";\n"])), colors.gray);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
