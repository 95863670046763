export var Routes;
(function (Routes) {
    Routes["HOME"] = "/";
    Routes["MY_ACCOUNT"] = "/my-account";
    Routes["MY_COMPANY"] = "/my-company";
    Routes["MY_COMPANY_PREFERENCES"] = "/my-company/preferences";
    // report
    Routes["REPORT"] = "/reports/:id";
    Routes["REPORTS"] = "/reports";
    // business hub
    Routes["BUSINESS_HUB"] = "/business-hub";
    // auth
    Routes["LOGIN"] = "/login";
    Routes["REGISTER"] = "/register";
    Routes["SAVE_PASSWORD"] = "/invitation/:token";
    Routes["PASSWORD_FORGOT"] = "/login/password-forgot";
    Routes["PASSWORD_RESET"] = "/login/password-reset";
    // onboarding
    Routes["ONBOARDING"] = "/onboarding";
    Routes["ONBOARDING_COMPANY_PROFILE"] = "/onboarding/company";
    Routes["ONBOARDING_USER_PROFILE"] = "/onboarding/user";
    // application
    Routes["APPLICATION"] = "/application";
    // investment
    Routes["INVESTMENT"] = "/investment";
    // portfolio
    Routes["PORTFOLIO"] = "/portfolio";
    Routes["PORTFOLIO_OVERVIEW"] = "/portfolio-overview";
    // test ui
    Routes["UI_EXAMPLES"] = "/ui-examples";
})(Routes || (Routes = {}));
