var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { humanizedTimeSpan } from "@/utils";
import { api } from "../client";
export function getRepayments(organizationId, organizationCurrency) {
    return __awaiter(this, void 0, void 0, function () {
        var query, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = organizationCurrency ? "?currency_code=" + organizationCurrency : "";
                    return [4 /*yield*/, api.get("/api/v1/organizations/" + organizationId + "/payable-repayments" + query)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.map(function (item) {
                            return {
                                organizationId: organizationId,
                                id: item.id,
                                total: item.total,
                                dueDate: item.due_date,
                                humanizedDueDate: humanizedTimeSpan(item.due_date, "overdue", "left"),
                                due: item.due,
                                repaymentCurrencyRate: item.repayment_currency_rate,
                                dateReceived: item.date_received,
                                received: item.received,
                                score: item.score,
                                name: "Repayment",
                                submittedAt: item.payment_date,
                                createdAt: item.created_at,
                                updatedAt: item.updated_at,
                                date: item.date,
                            };
                        })];
            }
        });
    });
}
export function setRepaymentAsPaid(repaymentId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/repayments/" + repaymentId + "/mark_as_paid")];
                case 1:
                    _a.sent();
                    return [2 /*return*/, {}];
            }
        });
    });
}
export function getPaymentMethods() {
    return api
        .get("/api/v1/payment_methods")
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
}
export function getOrganizationPaymentMethods(organizationId) {
    return api
        .get("/api/v1/organizations/" + organizationId + "/payment-methods")
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
}
export function getRepurchaseProgress(investmentId, currency) {
    var query = currency ? "?currency_code=" + currency : "";
    return api
        .get("/api/v1/investments/" + investmentId + "/repurchase_progress" + query)
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
}
export function createRepayment(_a) {
    var investmentId = _a.investmentId, repaymentData = _a.repaymentData;
    return api
        .post("/api/v1/investments/" + investmentId + "/payments", repaymentData)
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
}
export function updatePaymentDateConfirmed(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/api/v1/payments/" + params.id, {
                        date_confirmed: params.date_confirmed,
                    })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getPayment(id) {
    return api.get("/api/v1/payments/" + id).then(function (_a) {
        var data = _a.data;
        return data;
    });
}
export function getTransactionFee(country_id) {
    return api
        .get("/api/v1/countries/" + country_id + "/beyonic_transaction_fee")
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
}
export function getFlutterwaveTransactionFee(amount, currency) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/flutterwave/transaction_fee?amount=" + amount + "&currency=" + currency)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getFlutterwavePaymentLink(amount, currency) {
    return __awaiter(this, void 0, void 0, function () {
        var result0, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/flutterwave/payment_link?amount=" + amount + "&currency=" + currency)];
                case 1:
                    result0 = _a.sent();
                    data = result0.data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function verifyFlutterwaveTransaction(transaction_id) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/flutterwave/verify_payment?reference=" + transaction_id)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getFlutterwaveConversionRate(amount, currency, to_currency) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/flutterwave/conversion_rate?from_currency=" + currency + "&to_currency=" + to_currency + "&amount=" + amount)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function createFlutterwavePayment(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/flutterwave/create_payment", params)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
