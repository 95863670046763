var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getOnlyLetters, getFinancialReportName, humanizedTimeSpan, sortFinancialReports, sortNonFinancialReports, monthYear, } from "@/utils";
import { api } from "../client";
export function getNonFinancialReports(organizationId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/organizations/" + organizationId + "/non_financial_reports")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data
                            .map(function (item) {
                            return {
                                createdAt: item.created_at,
                                dueDate: item.due_date,
                                id: item.id,
                                name: "Reporting",
                                humanizedDueDate: humanizedTimeSpan(item.due_date, "overdue", "left"),
                                score: item.score,
                                date: item.date,
                                reportDate: item.report_date,
                                questions: item.questions.map(function (question) {
                                    return {
                                        answer: question.answer,
                                        questionId: question.question_id,
                                        question: question.question,
                                        questionShort: question.shortened_question || question.question.substring(0, 15) + "...",
                                        type: question.type,
                                        options: question.options,
                                    };
                                }),
                                submittedAt: item.submitted_at,
                            };
                        })
                            .sort(sortNonFinancialReports)];
            }
        });
    });
}
export function getNonFinancialReport(id) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/non_financial_reports/" + id)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, {
                            createdAt: data.created_at,
                            dueDate: data.due_date,
                            id: data.id,
                            name: "Reporting " + monthYear(data.report_date),
                            humanizedDueDate: humanizedTimeSpan(data.due_date, "overdue", "left"),
                            score: data.score,
                            date: data.date,
                            reportDate: data.report_date,
                            questions: data.questions.map(function (question) {
                                return {
                                    answer: question.answer,
                                    questionId: question.question_id,
                                    question: question.question,
                                    questionShort: question.shortened_question || question.question.substring(0, 15) + "...",
                                    type: question.type,
                                    options: question.options,
                                };
                            }),
                            submittedAt: data.submitted_at,
                        }];
            }
        });
    });
}
export function saveNonFinancialReport(params) {
    return __awaiter(this, void 0, void 0, function () {
        var id, answers;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = params.id, answers = params.answers;
                    return [4 /*yield*/, api.put("/api/v1/non_financial_reports/" + id, {
                            answers: answers.map(function (answer) {
                                return {
                                    answer: answer.answer,
                                    question_id: answer.questionId,
                                };
                            }),
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, {}];
            }
        });
    });
}
export function getFinancialReports(organizationId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/organizations/" + organizationId + "/financial_reports")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data
                            .filter(function (item) { return item.repayment; })
                            .map(function (item) {
                            return {
                                id: item.id,
                                name: getFinancialReportName(item.kind, item.date),
                                organizationId: item.organization_id,
                                kind: item.kind,
                                date: item.date,
                                repayment: {
                                    id: item.repayment.id,
                                    total: item.repayment.total,
                                    dueDate: item.repayment.due_date,
                                    humanizedDueDate: humanizedTimeSpan(item.repayment.due_date, "overdue", "left"),
                                    due: item.repayment.due,
                                    repaymentCurrencyRate: item.repayment.repayment_currency_rate,
                                    received: item.repayment.received,
                                    dateReceived: item.repayment.date_received,
                                    amountReceived: item.repayment.received.amount,
                                    score: item.repayment.score,
                                    organizationId: item.organization_id,
                                    name: "Repayment " + item.repayment.due_date,
                                    submittedAt: item.repayment.payment_date,
                                    paymentDate: item.repayment.payment_date,
                                    createdAt: item.repayment.created_at,
                                    updatedAt: item.repayment.updated_at,
                                    date: item.repayment.date,
                                },
                                financialIndicators: item.financial_indicators.map(function (indicator) {
                                    return {
                                        name: getOnlyLetters(indicator.name),
                                        value: indicator.value,
                                        type: indicator.type,
                                    };
                                }),
                            };
                        })
                            .sort(sortFinancialReports)];
            }
        });
    });
}
export function getInvestmentPayments(investmentId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/investments/" + investmentId + "/payments")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.map(function (item) {
                            return {
                                id: item.id,
                                amountValue: item.amount_value_format,
                                amountCurrencyCode: item.amount_currency_code,
                                paymentMethod: item.payment_method,
                                paymentDate: item.payment_date,
                                status: item.status,
                                dateConfirmed: item.date_confirmed,
                                createdAt: item.created_at_format,
                                investmentAmountValue: item.investment_amount_value,
                                investmentCurrencyCode: item.investment_currency_code,
                                investmentCurrencyRate: item.investment_currency_rate,
                            };
                        })];
            }
        });
    });
}
