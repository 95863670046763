export var media = {
    between: function (minWidth, maxWidth) {
        return "@media all and (min-width: " + (minWidth + 1) + "px) and (max-width: " + maxWidth + "px)";
    },
    max: function (width) {
        return "@media all and (max-width: " + width + "px)";
    },
    min: function (width) {
        return "@media all and (min-width: " + (width + 1) + "px)";
    },
};
