import React from "react";
import { ContextProviders, UserProvider, QueryProvider } from "@/contexts";
import { useAuthData } from "@/hooks";
import Router from "@/router";
import { GlobalStyle } from "./styles";
function App() {
    var _a = useAuthData(), token = _a.token, user = _a.user, preferences = _a.preferences, loading = _a.loading, organization = _a.organization, investorColors = _a.investorColors, investorSettings = _a.investorSettings;
    if (loading) {
        return null;
    }
    return (React.createElement(QueryProvider, null,
        React.createElement(UserProvider, { initialState: {
                token: token,
                user: user,
                preferences: preferences,
                organization: organization,
                investorColors: investorColors,
                investorSettings: investorSettings,
            } },
            React.createElement(ContextProviders, null,
                React.createElement(GlobalStyle, { investorColors: investorColors }),
                React.createElement(Router, null)))));
}
export default App;
