import React from "react";
import BrandContent from "../BrandLayout/BrandContent";
import { Wrapper, ErrorWrapper, Heading, ErrorDetails } from "./styles";
var FallbackErrorBoundary = function (_a) {
    var error = _a.error;
    return (React.createElement(Wrapper, null,
        React.createElement(BrandContent, null),
        React.createElement(ErrorWrapper, null,
            React.createElement(Heading, null, "Something went wrong"),
            React.createElement(ErrorDetails, null,
                "Error details: ",
                React.createElement("p", null, error.message)))));
};
export default FallbackErrorBoundary;
