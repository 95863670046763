import compose from "./compose";
import DashboardProvider from "./dashboard";
import QueryProvider from "./query";
import ReportProvider from "./report";
import ResourceCenterProvider from "./resourceCenter";
import UserProvider from "./user";
export * from "./dashboard";
export * from "./report";
export * from "./user";
var ContextProviders = compose([DashboardProvider, ReportProvider, ResourceCenterProvider]);
export { ContextProviders, DashboardProvider, ReportProvider, UserProvider, ResourceCenterProvider, QueryProvider, };
