import { useHistory } from "react-router-dom";
export function useRedirect() {
    var history = useHistory();
    var redirectTo = function (path, params) {
        history.push(path, params);
    };
    return {
        redirectTo: redirectTo,
    };
}
