var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFormik } from "formik";
export function useForm(_a) {
    var initialValues = _a.initialValues, validationSchema = _a.validationSchema, validate = _a.validate, onSubmit = _a.onSubmit, _b = _a.enableReinitialize, enableReinitialize = _b === void 0 ? false : _b, _c = _a.validateOnMount, validateOnMount = _c === void 0 ? false : _c;
    var formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: validationSchema,
        validate: validate,
        enableReinitialize: enableReinitialize,
        validateOnMount: validateOnMount,
    });
    var formFieldProps = function (name) { return ({
        name: name,
        value: formik.values[name],
        touched: formik.touched[name],
        error: formik.errors[name],
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
    }); };
    return __assign(__assign({}, formik), { formFieldProps: formFieldProps });
}
