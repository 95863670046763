var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import { api } from "../client";
export function createPresingedUrl(meta, file) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, _reject) {
                    var readChunked = function (chunkedFile, chunkCallback, endCallback) {
                        var fileSize = file.size;
                        var chunkSize = 4 * 1024 * 1024; // 4MB
                        var offset = 0;
                        var reader = new FileReader();
                        reader.onload = function () {
                            var _a;
                            if (reader.error) {
                                endCallback(reader.error || {});
                                return;
                            }
                            offset += typeof reader.result === "string" ? (_a = reader === null || reader === void 0 ? void 0 : reader.result) === null || _a === void 0 ? void 0 : _a.length : 0;
                            // callback for handling read chunk
                            chunkCallback(reader.result, offset, fileSize);
                            if (offset >= fileSize) {
                                endCallback(null);
                                return;
                            }
                            readNext();
                        };
                        reader.onerror = function (err) {
                            endCallback(err || {});
                        };
                        var readNext = function () {
                            var fileSlice = chunkedFile.slice(offset, offset + chunkSize);
                            reader.readAsBinaryString(fileSlice);
                        };
                        readNext();
                    };
                    function getMD5(blob) {
                        return new Promise(function (promResolve, promReject) {
                            var md5 = CryptoJS.algo.MD5.create();
                            readChunked(blob, function (chunk) {
                                md5.update(CryptoJS.enc.Latin1.parse(chunk));
                            }, function (err) {
                                if (err) {
                                    promReject(err);
                                }
                                else {
                                    var hash = md5.finalize();
                                    promResolve(Base64.stringify(hash));
                                }
                            });
                        });
                    }
                    getMD5(file).then(function (fileMd5Sum) { return __awaiter(_this, void 0, void 0, function () {
                        var data;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, api.post("/api/v1/direct_uploads", {
                                        blob: {
                                            filename: meta.name,
                                            byte_size: meta.size,
                                            checksum: fileMd5Sum,
                                            content_type: meta.type,
                                        },
                                    })];
                                case 1:
                                    data = (_a.sent()).data;
                                    resolve({
                                        url: data.direct_upload.url,
                                        headers: data.direct_upload.headers,
                                        signedId: data.signed_id,
                                        directUpload: data.direct_upload,
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                })];
        });
    });
}
