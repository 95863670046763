export var colors = {
    // Usage: Badges
    alto: "#D1D1D1",
    // Usage: Warning icon
    amaranth: "#EB3E48",
    // Usage: Info banner
    amourette: "#E0DFE8",
    // Usage: Dashboard background
    athensGrey: "#F8F8F9",
    // Usage: Fonts
    black: "#000000",
    // Usage: Labels
    cerise: "#EB3E48",
    // Usage: Icons
    chocolate: "#E36911",
    // Usage: Error messages
    crimson: "#C8102E",
    // Usage: Charts
    dustyGray: "#959595",
    // Usage: Box shadows
    gallery: "#ECECEC",
    // Usage: Charts
    gray: "#8F8F8F",
    // Usage: Icons
    green: "#5CA871",
    // Usage: Success color
    mantis: "#8CCB5C",
    // Usage: Active links
    maroonFlush: "#BB1B65",
    // Usage: Icons
    mineShaft: "#2F2D2D",
    // Usage: Inputs
    thunder: "#231F20",
    // Usage: Links
    transparent: "transparent",
    // Usage: Icons
    scorpion: "#6B6262",
    // Usage: Inputs
    silver: "#C0BFBF",
    // Usage: Icon color
    rollingStone: "#7A858D",
    // Usage: Background
    white: "#FFFFFF",
    // Usage: Badges
    yellowOrange: "#F9A042",
    // Usage: TOW
    kimberly: "#635F8D",
};
export var zIndex = {
    selectMenu: 1,
    topbar: 2,
    sidebar: 3,
    popup: 4,
    spinner: 5,
};
export var boxShadow = {
    shadow1: "0px 0px 2px 0px #00000033",
    shadow2: "0px 0px 6px 0px #00000033",
    shadow3: "0px 0px 12px 0px #00000033",
    shadow4: "0px 0px 24px 0px #0000003",
};
export var BRAND_GRADIENT = "116.2deg, #f9a042 26.84%, #bb1b65 80.73%";
export var BRAND_GRADIENT_ALT = "173.93deg, #F9A042 43.33%, #DF6950 96.73%";
export var MOBILE_RESOLUTION = 414;
export var LARGE_MOBILE_RESOLUTION = 576;
