export function roundToTwoDecimal(value) {
    return Number(Math.round(Number(value + "e+2")) + "e-2");
}
export function valuesPercentageIncrease(firstValue, secondValue) {
    var n1 = Number(firstValue);
    var n2 = Number(secondValue);
    if (Number(n2)) {
        return Math.round(((n2 - n1) / n2) * 100);
    }
    if (Number(n1)) {
        return Math.round(((n2 - n1) / n1) * 100);
    }
    return null;
}
