import { monthNames, shortMonthNames, LOCALE } from "@/constants";
export var NOW = Date.now();
export var NEW_DATE = new Date();
export var NEW_DATE_STRING = NEW_DATE.toString();
export var CURRENT_YEAR = NEW_DATE.getFullYear();
export function toTimestamp(date) {
    return Date.parse(date);
}
export function humanizedTimeSpan(date, pastDirection, futureDirection) {
    if (pastDirection === void 0) { pastDirection = "ago"; }
    if (futureDirection === void 0) { futureDirection = "from now"; }
    var seconds = Math.floor((NOW - toTimestamp(date)) / 1000);
    var unit = "second";
    var direction = pastDirection;
    if (seconds < 0) {
        seconds = -seconds;
        direction = futureDirection;
    }
    var value = seconds;
    if (seconds >= 31536000) {
        value = Math.floor(seconds / 31536000);
        unit = "year";
    }
    else if (seconds >= 86400) {
        value = Math.floor(seconds / 86400);
        unit = "day";
    }
    else if (seconds >= 3600) {
        value = Math.floor(seconds / 3600);
        unit = "hour";
    }
    else if (seconds >= 60) {
        value = Math.floor(seconds / 60);
        unit = "minute";
    }
    if (value !== 1) {
        unit += "s";
    }
    return value + " " + unit + " " + direction;
}
export function formatYyyyMmDdDate(initDate) {
    if (initDate === void 0) { initDate = NEW_DATE; }
    var date = new Date(initDate);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    var day = date.getDate().toString();
    if (month.length < 2) {
        month = "0" + month;
    }
    if (day.length < 2) {
        day = "0" + day;
    }
    return [year, month, day].join("-");
}
export function getFirstDayOfMonth(initDate) {
    if (initDate === void 0) { initDate = NEW_DATE_STRING; }
    var date = new Date(initDate);
    return formatYyyyMmDdDate(new Date(date.getFullYear(), date.getMonth(), 1));
}
export function getLastDayOfMonth(initDate) {
    if (initDate === void 0) { initDate = NEW_DATE_STRING; }
    var date = new Date(initDate);
    return formatYyyyMmDdDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
}
export function setMonthAgo(month) {
    var date = new Date();
    return new Date(date.setMonth(date.getMonth() - month)).toString();
}
export function setYearAgo(year) {
    var date = new Date();
    return new Date(date.setFullYear(date.getFullYear() - year));
}
export function getMonthNames(fromDateString, toDateString) {
    var fromDate = new Date(fromDateString);
    var toDate = new Date(toDateString);
    var fromYear = fromDate.getFullYear();
    var fromMonth = fromDate.getMonth();
    var toYear = toDate.getFullYear();
    var toMonth = toDate.getMonth();
    var months = [];
    for (var year = fromYear; year <= toYear; year++) {
        var month = year === fromYear ? fromMonth : 0;
        var monthLimit = year === toYear ? toMonth : 11;
        for (; month <= monthLimit; month++) {
            months.push(shortMonthNames[month]);
        }
    }
    return months;
}
export function isYearLeap(year) {
    return new Date(Number(year), 1, 29).getMonth() === 1;
}
export function getMonthName(dateString) {
    var date = new Date(dateString);
    var month = date.getMonth();
    return monthNames[month];
}
export function getMonth(dateString) {
    var date = new Date(dateString);
    return date.getMonth().toString().padStart(2, "0");
}
export function getYear(dateString) {
    var date = new Date(dateString);
    return date.getFullYear();
}
export function isBeforeNow(dateString) {
    return toTimestamp(dateString) < NOW;
}
export var previousMonthYear = function (date) {
    var currentDate = new Date(date);
    var previousMonth = currentDate.getMonth() - 1;
    var previousYear = currentDate.getFullYear();
    if (previousMonth === -1) {
        previousMonth = 11;
        previousYear -= 1;
    }
    var prevMonth = new Date(previousYear, currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1);
    var monthFormatter = new Intl.DateTimeFormat("en-US", { month: "long", year: "numeric" });
    var prevMonthName = monthFormatter.format(prevMonth);
    return prevMonthName;
};
export var monthYear = function (date) {
    return new Intl.DateTimeFormat(LOCALE, { month: "long", year: "numeric" }).format(new Date(date));
};
