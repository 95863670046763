import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import App from "@/app";
import FallbackErrorBoundary from "@/components/FallbackErrorBoundary";
import { BUGSNAG_FE_API_KEY } from "@/services/bugsnag";
Bugsnag.start({
    apiKey: BUGSNAG_FE_API_KEY || "0",
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: BUGSNAG_FE_API_KEY ? ["staging", "production"] : [],
});
var ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(ErrorBoundary, { FallbackComponent: FallbackErrorBoundary },
        React.createElement(App, null),
        React.createElement(Toaster, null))), document.getElementById("app"));
