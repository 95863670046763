var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { TodoEnum } from "@/constants";
import { assignTypeToTodos, filterNotSubmittedReports } from "@/utils";
import { getInvestments, valid_investment_statuses } from "./investment";
import { getRepayments } from "./repayment";
import { getInvestmentPayments, getNonFinancialReports } from "./report";
export function getTodos(organizationId, organizationCurrency) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, nonFinancialReports, payableRepayments, investments, validInvestments, payments, reports, repayments, pendingPayments, sumOfPendingPayments, sumOfPayableRepayments;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        getNonFinancialReports(organizationId),
                        getRepayments(organizationId, organizationCurrency),
                    ])];
                case 1:
                    _a = _b.sent(), nonFinancialReports = _a[0], payableRepayments = _a[1];
                    return [4 /*yield*/, getInvestments({ organizationId: organizationId }).then(function (r) { return r; })];
                case 2:
                    investments = _b.sent();
                    validInvestments = investments.filter(function (validInvestment) {
                        return valid_investment_statuses.includes(validInvestment.status);
                    });
                    return [4 /*yield*/, getInvestmentPayments(validInvestments[0].id)];
                case 3:
                    payments = _b.sent();
                    reports = filterNotSubmittedReports(assignTypeToTodos(nonFinancialReports, TodoEnum.NON_FINANCIAL_REPORT));
                    pendingPayments = payments.filter(function (payment) { return payment.status === "pending"; });
                    sumOfPendingPayments = pendingPayments.reduce(function (total, payment) { return total + Number(payment.amountValue); }, 0);
                    sumOfPayableRepayments = payableRepayments.reduce(function (total, repayment) { return total + Number(repayment.due.amount); }, 0);
                    if (sumOfPayableRepayments > sumOfPendingPayments) {
                        repayments = assignTypeToTodos(payableRepayments, TodoEnum.REPAYMENT);
                    }
                    else {
                        repayments = [];
                    }
                    return [2 /*return*/, __spreadArrays(reports, repayments).sort(function (a, b) {
                            return a.dueDate.localeCompare(b.dueDate);
                        })];
            }
        });
    });
}
