export var TodoEnum;
(function (TodoEnum) {
    TodoEnum["NON_FINANCIAL_REPORT"] = "non-financial-report";
    TodoEnum["REPAYMENT"] = "repayment";
    TodoEnum[TodoEnum["MAX_NON_FINANCIAL_REPORT_DISPLAY_DAYS"] = 30] = "MAX_NON_FINANCIAL_REPORT_DISPLAY_DAYS";
})(TodoEnum || (TodoEnum = {}));
export var ApplicationQuestionType;
(function (ApplicationQuestionType) {
    ApplicationQuestionType["RAVEN"] = "single_choice_of_images";
    ApplicationQuestionType["VIDEO"] = "video_file";
    ApplicationQuestionType["AUDIO"] = "audio_file";
    ApplicationQuestionType["AUDIO_VIDEO"] = "audio_or_video_file";
    ApplicationQuestionType["UPLOAD"] = "file";
    ApplicationQuestionType["TEXT"] = "text";
    ApplicationQuestionType["SINGLE"] = "single_choice";
    ApplicationQuestionType["MULTIPLE"] = "multiple_choice";
    ApplicationQuestionType["NUMBER"] = "number";
    ApplicationQuestionType["CURRENCY_AMOUNT"] = "currency_amount";
    ApplicationQuestionType["DATE_FIELD"] = "date_field";
})(ApplicationQuestionType || (ApplicationQuestionType = {}));
export var ExtraPayment;
(function (ExtraPayment) {
    ExtraPayment[ExtraPayment["MIN_PAYMENT_AMOUNT"] = 100] = "MIN_PAYMENT_AMOUNT";
})(ExtraPayment || (ExtraPayment = {}));
