import { useMemo, useState } from "react";
export function useError() {
    var _a = useState(), error = _a[0], setError = _a[1];
    var clearError = function () { return setError(null); };
    var errorMessage = useMemo(function () {
        return error
            ? error.response
                ? error.response.data.error
                : error.message
                    ? error.message
                    : error
            : "";
    }, [error]);
    return {
        errorMessage: errorMessage,
        setError: setError,
        clearError: clearError,
    };
}
