var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LOCALE } from "@/constants";
export function getFinancialReportName(kind, dateString) {
    var date = new Date(dateString);
    return kind + " Insights - " + date.toLocaleString(LOCALE, {
        month: "long",
    }) + " " + date.getUTCFullYear();
}
export function sortNonFinancialReports(a, b) {
    if (!a.submittedAt && !b.submittedAt)
        return -1;
    if (!a.submittedAt && b.submittedAt)
        return -1;
    if (a.submittedAt && !b.submittedAt)
        return 1;
    return 0;
}
export function sortFinancialReports(a, b) {
    if (!a.repayment.amountReceived &&
        !a.repayment.dateReceived &&
        b.repayment.amountReceived &&
        b.repayment.dateReceived)
        return -1;
    if (a.repayment.amountReceived &&
        a.repayment.dateReceived &&
        !b.repayment.amountReceived &&
        !b.repayment.dateReceived)
        return 1;
    if (!a.repayment.amountReceived &&
        !a.repayment.dateReceived &&
        !a.repayment.paymentDate &&
        !b.repayment.amountReceived &&
        !b.repayment.dateReceived &&
        b.repayment.paymentDate)
        return -1;
    if (!a.repayment.amountReceived &&
        !a.repayment.dateReceived &&
        a.repayment.paymentDate &&
        !b.repayment.amountReceived &&
        !b.repayment.dateReceived &&
        !b.repayment.paymentDate)
        return 1;
    return 0;
}
export function assignTypeToTodos(array, type) {
    return array.map(function (item) {
        return __assign(__assign({}, item), { type: type });
    });
}
export function filterNotSubmittedReports(array) {
    return array.filter(function (item) { return !item.submittedAt; });
}
