var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { media } from "@/utils";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background: ", ";\n  border-radius: 20px 0 0 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  height: 100%;\n  text-align: center;\n  width: 50%;\n\n  ", " {\n    border-radius: 0 0 20px 20px;\n    height: 13rem;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  background: ", ";\n  border-radius: 20px 0 0 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  height: 100%;\n  text-align: center;\n  width: 50%;\n\n  ", " {\n    border-radius: 0 0 20px 20px;\n    height: 13rem;\n    width: 100%;\n  }\n"])), function (_a) {
    var colors = _a.colors;
    return "" + (colors === null || colors === void 0 ? void 0 : colors["brand-color"]);
}, media.max(414));
export var BrandContentInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  display: flex-inline;\n  font-size: 30px;\n  font-weight: 700;\n  justify-content: center;\n  margin-top: 15rem;\n  text-align: left;\n  width: 65%;\n  padding: 1rem;\n\n  ", " {\n    font-size: 32px;\n  }\n\n  ", " {\n    font-size: 24px;\n    margin-top: 9rem;\n    width: 75%;\n  }\n\n  ", " {\n    font-size: 22px;\n    margin-top: 7rem;\n    padding: 0.5rem;\n  }\n\n  ", " {\n    margin-top: 5rem;\n    width: 85%;\n  }\n\n  ", " {\n    margin-top: 3rem;\n    font-size: 18px;\n  }\n\n  ", " {\n    margin-top: 0;\n    font-size: 23px;\n    width: 80%;\n  }\n"], ["\n  color: ", ";\n  display: flex-inline;\n  font-size: 30px;\n  font-weight: 700;\n  justify-content: center;\n  margin-top: 15rem;\n  text-align: left;\n  width: 65%;\n  padding: 1rem;\n\n  ", " {\n    font-size: 32px;\n  }\n\n  ", " {\n    font-size: 24px;\n    margin-top: 9rem;\n    width: 75%;\n  }\n\n  ", " {\n    font-size: 22px;\n    margin-top: 7rem;\n    padding: 0.5rem;\n  }\n\n  ", " {\n    margin-top: 5rem;\n    width: 85%;\n  }\n\n  ", " {\n    margin-top: 3rem;\n    font-size: 18px;\n  }\n\n  ", " {\n    margin-top: 0;\n    font-size: 23px;\n    width: 80%;\n  }\n"])), function (_a) {
    var colors = _a.colors;
    return "" + (colors === null || colors === void 0 ? void 0 : colors["app-text-color"]);
}, media.min(1440), media.max(1040), media.max(890), media.max(768), media.max(576), media.max(414));
export var BrandContentImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: auto;\n  margin-bottom: 2rem;\n\n  ", " {\n    display: none;\n  }\n"], ["\n  margin-top: auto;\n  margin-bottom: 2rem;\n\n  ", " {\n    display: none;\n  }\n"])), media.max(414));
var templateObject_1, templateObject_2, templateObject_3;
