import { useState, useCallback, useEffect } from "react";
export function useCarousel(_a) {
    var length = _a.length, slideInterval = _a.slideInterval, autorun = _a.autorun;
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var _c = useState(new Date()), reset = _c[0], setReset = _c[1];
    var increaseStep = useCallback(function () {
        setStep(function (currentStep) { return (currentStep === length ? 0 : currentStep + 1); });
    }, [length, setStep]);
    var goTo = function (value) {
        setStep(value);
        setReset(new Date());
    };
    useEffect(function () {
        var interval = autorun ? setInterval(function () { return increaseStep(); }, slideInterval) : undefined;
        return function () {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [autorun, increaseStep, slideInterval, reset]);
    return { step: step, goTo: goTo };
}
