import { LOCALE, DEFAULT_INVESTMENT_CURRENCY } from "@/constants";
export function getCurrency(value) {
    return new Intl.NumberFormat(LOCALE).format(Number(value));
}
export function getCurrencyWithCode(value, code) {
    if (code === void 0) { code = DEFAULT_INVESTMENT_CURRENCY; }
    var currencyString = new Intl.NumberFormat(LOCALE, {
        currency: code,
        style: "currency",
    }).format(Number(value));
    var currencyNumber = Number(currencyString.replace(/[^0-9.-]+/g, ""));
    return currencyNumber;
}
export function getModifiedCurrencyOutput(value, code) {
    if (code === void 0) { code = DEFAULT_INVESTMENT_CURRENCY; }
    return getCurrencyWithCode(value, code).toLocaleString().replace(/\s/g, ",");
}
export function getCurrencyWithSymbol(value, symbol) {
    if (symbol === void 0) { symbol = DEFAULT_INVESTMENT_CURRENCY; }
    return getCurrency(value) + " " + symbol;
}
