var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import omit from "lodash/omit";
import { api } from "@/services/api";
var normalizeQuestionsResponse = function (_a) {
    var data = _a.data;
    return (__assign(__assign({}, data), { questions: data.questions.map(function (q) { return (__assign(__assign({}, omit(q, "chosen_option_id")), { chosenOptionId: q.chosen_option_id })); }) }));
};
export var getEligibilityTest = function (organizationId, portfolioId) {
    return api
        .get("/api/v1/organizations/" + organizationId + "/eligibility-test?portfolio_id=" + portfolioId)
        .then(normalizeQuestionsResponse);
};
export var submitEligibilityTest = function (organizationId, _a) {
    var questions = _a.questions;
    var answers = questions.map(function (question) { return ({
        id: question.id,
        chosen_option_id: question.chosenOptionId,
    }); });
    var saveRequestData = {
        operation: "save",
        payload: { answers: answers },
    };
    var submitRequestData = {
        operation: "submit",
        payload: {},
    };
    var getRequest = function (data) {
        return api.patch("/api/v1/organizations/" + organizationId + "/eligibility-test", data);
    };
    return getRequest(saveRequestData)
        .then(function () { return getRequest(submitRequestData); })
        .then(normalizeQuestionsResponse);
};
