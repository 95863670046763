import { useEffect, useState } from "react";
export var useDetectOutsideClick = function (element, initialState) {
    var _a = useState(initialState), isActive = _a[0], setIsActive = _a[1];
    useEffect(function () {
        var pageClickEvent = function (event) {
            if (element.current !== null && !element.current.contains(event.target)) {
                setIsActive(!isActive);
            }
        };
        if (isActive) {
            window.addEventListener("click", pageClickEvent);
        }
        return function () {
            window.removeEventListener("click", pageClickEvent);
        };
    }, [isActive, element]);
    return [isActive, setIsActive];
};
