var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useCallback, useContext, useState } from "react";
import { useError } from "@/hooks";
import { getFinancialReports, getInvestmentPayments, getInvestments, getNonFinancialReports, getRepayments, getTransactionFee, setRepaymentAsPaid, valid_investment_statuses, } from "@/services/api";
import { asyncNoop, noop } from "@/utils";
import { ReportsType } from "./types";
var Context = createContext({
    isNonFinancialReportsTypeList: true,
    financialReports: [],
    nonFinancialReports: [],
    payments: [],
    isRepaymentsInitial: true,
    repayments: [],
    transactionFee: { percentage: 0, factor: 0 },
    reportsType: ReportsType.NON_FINANCIAL,
    fetchFinancialReports: asyncNoop,
    fetchNonFinancialReports: asyncNoop,
    fetchPayments: asyncNoop,
    fetchReports: asyncNoop,
    fetchRepayments: asyncNoop,
    fetchTransactionFee: asyncNoop,
    markRepaymentAsPaid: asyncNoop,
    setFinancialReports: noop,
    setNonFinancialReports: noop,
    setRepayments: noop,
    setTransactionFee: noop,
    setPayments: noop,
    setReportsType: noop,
    reportErrorMessage: "",
});
function ReportProvider(props) {
    var _this = this;
    var _a = useError(), reportErrorMessage = _a.errorMessage, setError = _a.setError;
    var _b = useState(true), isRepaymentsInitial = _b[0], setIsRepaymentsInitial = _b[1];
    var _c = useState([]), repayments = _c[0], setRepaymentsData = _c[1];
    var _d = useState({
        percentage: 0,
        factor: 0,
    }), transactionFee = _d[0], setTransactionFee = _d[1];
    var _e = useState([]), payments = _e[0], setPaymentsData = _e[1];
    var _f = useState([]), nonFinancialReports = _f[0], setNonFinancialReportsData = _f[1];
    var _g = useState([]), financialReports = _g[0], setFinancialReportsData = _g[1];
    var _h = useState(ReportsType.NON_FINANCIAL), reportsType = _h[0], setReportsTypeFlag = _h[1];
    var isNonFinancialReportsTypeList = reportsType === ReportsType.NON_FINANCIAL;
    var setRepayments = useCallback(function (data) {
        setRepaymentsData(data);
    }, [setRepaymentsData]);
    var setNonFinancialReports = useCallback(function (data) {
        setNonFinancialReportsData(data);
    }, [setNonFinancialReportsData]);
    var setFinancialReports = useCallback(function (data) {
        setFinancialReportsData(data);
    }, [setFinancialReportsData]);
    var setPayments = useCallback(function (data) {
        setPaymentsData(data);
    }, [setPaymentsData]);
    var setReportsType = useCallback(function (type) {
        setReportsTypeFlag(type);
    }, [setReportsTypeFlag]);
    var fetchPayments = useCallback(function (organizationId) { return __awaiter(_this, void 0, void 0, function () {
        var investments, validInvestments, paymentsData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, getInvestments({ organizationId: organizationId }).then(function (r) { return r; })];
                case 2:
                    investments = _a.sent();
                    validInvestments = investments.filter(function (validInvestment) {
                        return valid_investment_statuses.includes(validInvestment.status);
                    });
                    return [4 /*yield*/, getInvestmentPayments(validInvestments[0].id)];
                case 3:
                    paymentsData = _a.sent();
                    setPayments(paymentsData);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    setError(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [setError, setPayments]);
    var fetchNonFinancialReports = useCallback(function (organizationId) { return __awaiter(_this, void 0, void 0, function () {
        var nonFinancialReportsData, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getNonFinancialReports(organizationId)];
                case 2:
                    nonFinancialReportsData = _a.sent();
                    setNonFinancialReports(nonFinancialReportsData);
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    setError(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setError, setNonFinancialReports]);
    var fetchFinancialReports = useCallback(function (organizationId) { return __awaiter(_this, void 0, void 0, function () {
        var financialReportsData, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getFinancialReports(organizationId)];
                case 2:
                    financialReportsData = _a.sent();
                    setFinancialReports(financialReportsData);
                    return [3 /*break*/, 4];
                case 3:
                    err_3 = _a.sent();
                    setError(err_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setError, setFinancialReports]);
    var fetchReports = useCallback(function (organizationId) { return __awaiter(_this, void 0, void 0, function () {
        var err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 6];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, fetchNonFinancialReports(organizationId)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, fetchFinancialReports(organizationId)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, fetchPayments(organizationId)];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    err_4 = _a.sent();
                    setError(err_4);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [fetchFinancialReports, fetchNonFinancialReports, setError, fetchPayments]);
    var fetchRepayments = useCallback(function (organizationId, organizationCurrency) { return __awaiter(_this, void 0, void 0, function () {
        var repaymentsData, err_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getRepayments(organizationId, organizationCurrency)];
                case 2:
                    repaymentsData = _a.sent();
                    setRepayments(repaymentsData);
                    setIsRepaymentsInitial(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_5 = _a.sent();
                    setError(err_5);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setError, setRepayments]);
    var fetchTransactionFee = useCallback(function (organizationId, country_id) { return __awaiter(_this, void 0, void 0, function () {
        var fetchedtransactionFee, err_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getTransactionFee(country_id)];
                case 2:
                    fetchedtransactionFee = _a.sent();
                    setTransactionFee(fetchedtransactionFee);
                    return [3 /*break*/, 4];
                case 3:
                    err_6 = _a.sent();
                    setError(err_6);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setError, setTransactionFee]);
    var markRepaymentAsPaid = useCallback(function (repaymentId) { return __awaiter(_this, void 0, void 0, function () {
        var err_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, setRepaymentAsPaid(repaymentId)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    err_7 = _a.sent();
                    setError(err_7);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [setError]);
    return (React.createElement(Context.Provider, __assign({}, props, { value: {
            financialReports: financialReports,
            nonFinancialReports: nonFinancialReports,
            payments: payments,
            isNonFinancialReportsTypeList: isNonFinancialReportsTypeList,
            isRepaymentsInitial: isRepaymentsInitial,
            repayments: repayments,
            transactionFee: transactionFee,
            reportsType: reportsType,
            fetchFinancialReports: fetchFinancialReports,
            fetchNonFinancialReports: fetchNonFinancialReports,
            fetchPayments: fetchPayments,
            fetchReports: fetchReports,
            fetchRepayments: fetchRepayments,
            fetchTransactionFee: fetchTransactionFee,
            markRepaymentAsPaid: markRepaymentAsPaid,
            setFinancialReports: setFinancialReports,
            setNonFinancialReports: setNonFinancialReports,
            setRepayments: setRepayments,
            setPayments: setPayments,
            setTransactionFee: setTransactionFee,
            setReportsType: setReportsType,
            reportErrorMessage: reportErrorMessage,
        } })));
}
export * from "./types";
export function useReportContext() {
    return useContext(Context);
}
export default ReportProvider;
