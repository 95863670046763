export function openBlankLink(url) {
    if (typeof window !== "undefined") {
        window.open(url, "_blank");
    }
}
export function openLink(url) {
    if (typeof window !== "undefined") {
        window.location.href = url;
    }
}
export function lazyRetry(componentImport, maxRetries) {
    if (maxRetries === void 0) { maxRetries = 2; }
    var retryCount = 0;
    var importWithRetry = function () {
        return componentImport().catch(function (error) {
            retryCount++;
            if (retryCount <= maxRetries) {
                // Retry importing the component
                return importWithRetry();
            }
            throw error; // All retries exhausted, throw the error
        });
    };
    return new Promise(function (resolve, reject) {
        // check if the window has already been refreshed
        var hasRefreshed = JSON.parse(window.sessionStorage.getItem("retry-lazy-refreshed") || "false");
        importWithRetry()
            .then(function (component) {
            window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
            resolve(component);
        })
            .catch(function (error) {
            if (!hasRefreshed) {
                // not been refreshed yet
                window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
                window.location.reload(); // refresh the page
            }
            else {
                reject(error); // Default error behavior as already tried refresh
            }
        });
    });
}
