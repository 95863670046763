export var AUTH_TOKEN = "uncap-auth-token";
export function saveAuthToken(token) {
    // Token will be valid one day
    var expires = Date.now() + 1000 * 60 * 60 * 24;
    var authToken = JSON.stringify({ token: token, expires: expires });
    localStorage.setItem(AUTH_TOKEN, authToken);
    document.cookie = AUTH_TOKEN + "=" + authToken;
}
export function removeAuthToken() {
    localStorage.removeItem(AUTH_TOKEN);
    document.cookie = AUTH_TOKEN + "=";
}
export function getAuthToken() {
    var rawAuthToken = localStorage.getItem(AUTH_TOKEN);
    if (!rawAuthToken) {
        return null;
    }
    try {
        var _a = JSON.parse(rawAuthToken), token = _a.token, expires = _a.expires;
        if (token) {
            if (expires <= Date.now()) {
                removeAuthToken();
                return null;
            }
            return token;
        }
    }
    catch (err) {
        // Probably JSON.parse error.
        // Remove this item from local storage since it's useless.
        if (err instanceof SyntaxError && err.stack && err.stack.indexOf("JSON.parse") !== -1) {
            removeAuthToken();
        }
        else {
            throw err;
        }
    }
    return null;
}
