var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
import { api } from "../client";
function formatOrganizationProfileResponse(companyProfile) {
    var created_at = companyProfile.created_at, access_to_dashboard = companyProfile.access_to_dashboard, contract_is_signed = companyProfile.contract_is_signed, letter_of_intent_is_signed = companyProfile.letter_of_intent_is_signed, bank_account_number = companyProfile.bank_account_number, bank_address = companyProfile.bank_address, bank_name = companyProfile.bank_name, bank_swift_code = companyProfile.bank_swift_code, bank_account_name = companyProfile.bank_account_name, bank_branch = companyProfile.bank_branch, bank_branch_code = companyProfile.bank_branch_code, founded_date = companyProfile.founded_date, postal_box = companyProfile.postal_box, postal_code = companyProfile.postal_code, investment_exists = companyProfile.investment_exists, logo_path = companyProfile.logo_path, valid_access_to_accounting_system = companyProfile.valid_access_to_accounting_system, investment_currency = companyProfile.investment_currency, business_currency = companyProfile.business_currency, profile = __rest(companyProfile, ["created_at", "access_to_dashboard", "contract_is_signed", "letter_of_intent_is_signed", "bank_account_number", "bank_address", "bank_name", "bank_swift_code", "bank_account_name", "bank_branch", "bank_branch_code", "founded_date", "postal_box", "postal_code", "investment_exists", "logo_path", "valid_access_to_accounting_system", "investment_currency", "business_currency"]);
    return __assign(__assign({}, profile), { createdAt: created_at, accessToDashboard: access_to_dashboard, bankAddress: bank_address, bankName: bank_name, bankSwiftCode: bank_swift_code, bankAccountNumber: bank_account_number, bankAccountName: bank_account_name, bankBranch: bank_branch, bankBranchCode: bank_branch_code, contractIsSigned: contract_is_signed, letterOfIntentIsSigned: letter_of_intent_is_signed, foundedDate: founded_date, investmentExists: investment_exists, logoPath: logo_path, postalBox: postal_box, postalCode: postal_code, validAccessToAccountingSystem: valid_access_to_accounting_system, investmentCurrency: investment_currency, businessCurrency: business_currency });
}
function formatUserProfileResponse(userProfile) {
    var avatar_path = userProfile.avatar_path, date_of_birth = userProfile.date_of_birth, first_name = userProfile.first_name, job_title = userProfile.job_title, last_name = userProfile.last_name, middle_name = userProfile.middle_name, verified_identity = userProfile.verified_identity, source_self_reported = userProfile.source_self_reported, mobile_payment_phone = userProfile.mobile_payment_phone, profile = __rest(userProfile, ["avatar_path", "date_of_birth", "first_name", "job_title", "last_name", "middle_name", "verified_identity", "source_self_reported", "mobile_payment_phone"]);
    return __assign(__assign({}, profile), { avatarPath: avatar_path, dateOfBirth: date_of_birth, firstName: first_name, jobTitle: job_title, lastName: last_name, middleName: middle_name, userSource: source_self_reported, verifiedIdentity: verified_identity, mobilePaymentPhone: mobile_payment_phone });
}
export function getUser() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/user")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, {
                            avatarPath: data.avatar_path,
                            email: data.email,
                            userHash: data.user_hash,
                            createdAt: data.created_at,
                            firstName: data.first_name,
                            gender: data.gender,
                            userSource: data.source_self_reported,
                            id: data.id,
                            lastName: data.last_name,
                            middleName: data.middle_name,
                            organizationId: data.organization_id,
                            organizationName: data.organization_name,
                            permissions: data.permissions,
                            verifiedIdentity: data.verified_identity,
                            organizationCurrency: data.organization_currency,
                            mobilePaymentPhone: data.mobile_payment_phone,
                            phone: data.phone,
                            organizationEligibility: data.organization_eligibility,
                            organizationApplications: data.organization_applications,
                        }];
            }
        });
    });
}
export function getOrganization() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/organization")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatOrganizationProfileResponse(data)];
            }
        });
    });
}
export function getIdentityVerification() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/user/identity_verification")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, {
                            identityVerifyUrl: data.verify_url,
                            identityVerified: data.verified_identity,
                            errorMessage: data.error_message,
                        }];
            }
        });
    });
}
export function getUserPreferences() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/user/preferences")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getUserRefCode() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/user/ref_code")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.ref_code];
            }
        });
    });
}
export function updateUserPreferences(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.patch("api/v1/user/preferences", params)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getUserProfile() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/user_profile")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatUserProfileResponse(data)];
            }
        });
    });
}
export function updateUserProfile(params) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.patch("/api/v1/user_profile", {
                        country_id: (_a = params.country) === null || _a === void 0 ? void 0 : _a.id,
                        date_of_birth: params.dateOfBirth,
                        first_name: params.firstName,
                        middle_name: params.middleName,
                        gender: params.gender,
                        last_name: params.lastName,
                        job_title: params.jobTitle,
                        phone: params.phone,
                        mobile_payment_phone: params.mobilePaymentPhone,
                        source_self_reported: params.userSource,
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, formatUserProfileResponse(data)];
            }
        });
    });
}
export function createOrganizationProfile(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/organization", {
                        name: params.companyName,
                        country_id: params.countryId,
                        industry_id: params.industryId,
                    })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatOrganizationProfileResponse(data)];
            }
        });
    });
}
export function updateOrganizationProfile(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.patch("/api/v1/organization", {
                        address: params.address,
                        bank_account_number: params.bankAccountNumber,
                        bank_address: params.bankAddress,
                        bank_name: params.bankName,
                        bank_swift_code: params.bankSwiftCode,
                        bank_account_name: params.bankAccountName,
                        bank_branch: params.bankBranch,
                        bank_branch_code: params.bankBranchCode,
                        city: params.city,
                        country_id: params.countryId,
                        founded_date: params.foundedDate,
                        postal_box: params.postalBox,
                        postal_code: params.postalCode,
                        name: params.name,
                        description: params.description,
                        linkedin: params.linkedin,
                        industry_id: params.industryId,
                        public: params.public,
                        logo_signed_id: params.logoSignedId,
                        website: params.website,
                    })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatOrganizationProfileResponse(data)];
            }
        });
    });
}
export function updateUserAvatar(avatarSignedId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.patch("/api/v1/user_profile", {
                        avatar_signed_id: avatarSignedId,
                    })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatUserProfileResponse(data)];
            }
        });
    });
}
export function updateOrganizationLogo(logoSignedId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.patch("/api/v1/organization", {
                        logo_signed_id: logoSignedId,
                    })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatOrganizationProfileResponse(data)];
            }
        });
    });
}
export function uploadFile(params) {
    return __awaiter(this, void 0, void 0, function () {
        var formData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formData = new FormData();
                    formData.append("files", params.file);
                    return [4 /*yield*/, axios.put(params.url, params.file, {
                            headers: params.headers,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function checkIfEmailExistsInAnotherOrganization(email, organizationId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/organizations/" + organizationId + "/check_email", {
                        email: email,
                    })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function inviteCoFounders(email, organisationId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/organizations/" + organisationId + "/co-founders", {
                        email: email,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, {}];
            }
        });
    });
}
export function getCoFounders(organisationId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/organizations/" + organisationId + "/co-founders")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.map(function (d) { return ({
                            id: d.id,
                            email: d.email,
                            firstName: d.first_name,
                            lastName: d.last_name,
                            middleName: d.middle_name,
                            status: d.status,
                        }); })];
            }
        });
    });
}
export function detachCofounders(organizationId, coFounderId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.delete("/api/v1/organizations/" + organizationId + "/co-founders/" + coFounderId)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function getInvestorSettings(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/investor_settings", { params: params })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
