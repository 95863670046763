import axios from "axios";
import toast from "react-hot-toast";
export var api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    xsrfCookieName: "CSRF-TOKEN",
    xsrfHeaderName: "X-CSRF-Token",
    withCredentials: true,
});
api.interceptors.response.use(undefined, function (error) {
    if (!["/api/v1/oauth/token/"].includes(error.response.config.url)) {
        toast.error("" + error.response.data.error, {
            position: "bottom-center",
            style: {
                background: "#fff",
                wordWrap: "break-word",
                color: "#EB3E48",
                borderLeft: "5px solid #EB3E48",
                borderRadius: "5px",
                padding: "10px",
                fontFamily: "Montserrat",
                fontSize: "12px",
                lineHeight: "15px",
                minWidth: "256px",
            },
            duration: 5000,
        });
    }
    return Promise.reject(error);
});
api.defaults.headers["Content-Type"] = "application/json";
