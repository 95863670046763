export function getOnlyLetters(value) {
    return value.replace(/[^a-zA-Z]/g, " ");
}
export function convertStringToShortNumber(value) {
    var numberValue = Number(value);
    if (numberValue >= 1000000) {
        value = numberValue / 1000000 + "m";
    }
    else if (numberValue >= 1000) {
        value = numberValue / 1000 + "k";
    }
    return value;
}
export function changeDashToSlashString(value) {
    if (!value) {
        return "";
    }
    return value.replace(/-/g, "/");
}
export function capitalize(string) {
    if (string.toLowerCase() === "ltm")
        return "LTM";
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function capitalizeFirstWord(string) {
    var _a = string.split(" "), firstWord = _a[0], rest = _a.slice(1);
    return capitalize(firstWord) + " " + rest.join(" ");
}
export function capitalizeWords(string) {
    return string.split(" ").map(capitalize).join(" ");
}
export function snakeCaseToHuman(string) {
    return string.replace(/_/g, " ");
}
