var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useCallback, useContext, useState } from "react";
import { useError } from "@/hooks";
import { getDigitalIdentityStats, getKpis, getTodos, getRevenues } from "@/services/api";
import { asyncNoop, formatYyyyMmDdDate, noop, setYearAgo } from "@/utils";
import { defaultDigitalIdentityStats, getDates } from "./utils";
var Context = createContext({
    dashboardErrorMessage: "",
    digitalIdentityStats: defaultDigitalIdentityStats,
    fetchDigitalIdentityStats: asyncNoop,
    fetchKpis: asyncNoop,
    fetchKpisToCompare: asyncNoop,
    fetchMyKpis: asyncNoop,
    fetchTodos: asyncNoop,
    fetchRevenues: asyncNoop,
    kpis: [],
    kpisToCompare: [],
    myKpis: [],
    todos: [],
    setDigitalIdentityStats: noop,
    setKpis: noop,
    setKpisToCompare: noop,
    setTodos: noop,
    setRevenues: noop,
    setRevenuesSummary: noop,
    revenues: [],
    revenuesSummary: null,
});
function DashboardProvider(props) {
    var _this = this;
    var _a = useError(), dashboardErrorMessage = _a.errorMessage, setError = _a.setError;
    var _b = useState([]), todos = _b[0], setTodosData = _b[1];
    var _c = useState(defaultDigitalIdentityStats), digitalIdentityStats = _c[0], setDigitalIdentityStatsData = _c[1];
    var _d = useState([]), kpis = _d[0], setKpisData = _d[1];
    var _e = useState([]), kpisToCompare = _e[0], setKpisToCompareData = _e[1];
    var _f = useState([]), myKpis = _f[0], setMyKpisData = _f[1];
    var _g = useState([]), revenues = _g[0], setRevenuesData = _g[1];
    var _h = useState(null), revenuesSummary = _h[0], setRevenuesSummaryData = _h[1];
    var setTodos = useCallback(function (data) {
        setTodosData(data);
    }, [setTodosData]);
    var setDigitalIdentityStats = useCallback(function (data) {
        setDigitalIdentityStatsData(data);
    }, [setDigitalIdentityStatsData]);
    var setKpis = useCallback(function (data) {
        setKpisData(data);
    }, [setKpisData]);
    var setMyKpis = useCallback(function (data) {
        setMyKpisData(data);
    }, [setMyKpisData]);
    var setKpisToCompare = useCallback(function (data) {
        setKpisToCompareData(data);
    }, [setKpisToCompareData]);
    var setRevenues = useCallback(function (data) {
        setRevenuesData(data);
    }, [setRevenuesData]);
    var setRevenuesSummary = useCallback(function (data) {
        setRevenuesSummaryData(data);
    }, [setRevenuesSummaryData]);
    var fetchTodos = useCallback(function (organizationId) { return __awaiter(_this, void 0, void 0, function () {
        var todosData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getTodos(organizationId)];
                case 2:
                    todosData = _a.sent();
                    setTodos(todosData);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    setError(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setTodos, setError]);
    var fetchDigitalIdentityStats = useCallback(function (organizationId) { return __awaiter(_this, void 0, void 0, function () {
        var digitalIdentityStatsData, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!organizationId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getDigitalIdentityStats(organizationId)];
                case 2:
                    digitalIdentityStatsData = _a.sent();
                    setDigitalIdentityStats(digitalIdentityStatsData);
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    setError(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setDigitalIdentityStats, setError]);
    var fetchMyKpis = useCallback(function (_a) {
        var currency = _a.currency, organizationId = _a.organizationId;
        return __awaiter(_this, void 0, void 0, function () {
            var date, kpisData, err_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!organizationId) return [3 /*break*/, 4];
                        date = {
                            startDate: formatYyyyMmDdDate(setYearAgo(1)),
                            endDate: formatYyyyMmDdDate(),
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getKpis({
                                currency: currency,
                                date: date,
                                organizationId: organizationId,
                            })];
                    case 2:
                        kpisData = _b.sent();
                        setMyKpis(kpisData);
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _b.sent();
                        setError(err_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, [setMyKpis, setError]);
    var fetchKpis = useCallback(function (_a) {
        var currency = _a.currency, datesRange = _a.datesRange, organizationId = _a.organizationId, year = _a.year;
        return __awaiter(_this, void 0, void 0, function () {
            var date, kpisData, err_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!organizationId) return [3 /*break*/, 4];
                        date = getDates(datesRange, year);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getKpis({
                                currency: currency,
                                date: date,
                                organizationId: organizationId,
                            })];
                    case 2:
                        kpisData = _b.sent();
                        setKpis(kpisData);
                        return [3 /*break*/, 4];
                    case 3:
                        err_4 = _b.sent();
                        setError(err_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, [setKpis, setError]);
    var fetchKpisToCompare = useCallback(function (_a) {
        var currency = _a.currency, datesRange = _a.datesRange, organizationId = _a.organizationId, year = _a.year;
        return __awaiter(_this, void 0, void 0, function () {
            var date, kpisData, err_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!organizationId) return [3 /*break*/, 4];
                        date = getDates(datesRange, year);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getKpis({
                                currency: currency,
                                date: date,
                                organizationId: organizationId,
                            })];
                    case 2:
                        kpisData = _b.sent();
                        setKpisToCompare(kpisData);
                        return [3 /*break*/, 4];
                    case 3:
                        err_5 = _b.sent();
                        setError(err_5);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, [setKpisToCompare, setError]);
    var fetchRevenues = useCallback(function (_a) {
        var currency = _a.currency, startDate = _a.startDate, endDate = _a.endDate, organizationId = _a.organizationId;
        return __awaiter(_this, void 0, void 0, function () {
            var revenuesData, err_6;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!organizationId) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getRevenues({
                                currency: currency,
                                startDate: startDate,
                                endDate: endDate,
                                organizationId: organizationId,
                            })];
                    case 2:
                        revenuesData = _b.sent();
                        if (revenuesData) {
                            setRevenuesSummary(revenuesData.summary);
                            setRevenues(revenuesData.data);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_6 = _b.sent();
                        setError(err_6);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, [setRevenues, setRevenuesSummary, setError]);
    return (React.createElement(Context.Provider, __assign({}, props, { value: {
            dashboardErrorMessage: dashboardErrorMessage,
            digitalIdentityStats: digitalIdentityStats,
            fetchDigitalIdentityStats: fetchDigitalIdentityStats,
            fetchKpis: fetchKpis,
            fetchKpisToCompare: fetchKpisToCompare,
            fetchMyKpis: fetchMyKpis,
            fetchTodos: fetchTodos,
            fetchRevenues: fetchRevenues,
            kpis: kpis,
            kpisToCompare: kpisToCompare,
            myKpis: myKpis,
            todos: todos,
            setDigitalIdentityStats: setDigitalIdentityStats,
            setKpis: setKpis,
            setKpisToCompare: setKpisToCompare,
            setTodos: setTodos,
            setRevenues: setRevenues,
            setRevenuesSummary: setRevenuesSummary,
            revenues: revenues,
            revenuesSummary: revenuesSummary,
        } })));
}
export * from "./types";
export * from "./utils";
export function useDashboardContext() {
    return useContext(Context);
}
export default DashboardProvider;
