import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routes } from "@/constants";
import { lazyRetry } from "@/utils";
var Dashboard = lazy(function () { return lazyRetry(function () { return import("@/pages/Dashboard"); }); });
var Login = lazy(function () { return lazyRetry(function () { return import("@/pages/Login"); }); });
var Register = lazy(function () { return lazyRetry(function () { return import("@/pages/Register"); }); });
var SavePassword = lazy(function () { return lazyRetry(function () { return import("@/pages/SavePassword"); }); });
var ResetPassword = lazy(function () { return lazyRetry(function () { return import("@/pages/ResetPassword"); }); });
var ForgotPassword = lazy(function () { return lazyRetry(function () { return import("@/pages/ForgotPassword"); }); });
var MyAccount = lazy(function () { return lazyRetry(function () { return import("@/pages/MyAccount"); }); });
var MyCompany = lazy(function () { return lazyRetry(function () { return import("@/pages/MyCompany"); }); });
var Reports = lazy(function () { return lazyRetry(function () { return import("@/pages/Reports"); }); });
var ResourceCenter = lazy(function () { return lazyRetry(function () { return import("@/pages/ResourceCenter"); }); });
var Application = lazy(function () { return lazyRetry(function () { return import("@/pages/Application"); }); });
var Onboarding = lazy(function () { return lazyRetry(function () { return import("@/pages/Onboarding"); }); });
var OnboardingUserProfile = lazy(function () {
    return lazyRetry(function () { return import("@/pages/Onboarding/OnboardingUserProfile"); });
});
var OnboardingCompanyProfile = lazy(function () {
    return lazyRetry(function () { return import("@/pages/Onboarding/OnboardingCompanyProfile"); });
});
var Investment = lazy(function () { return lazyRetry(function () { return import("@/pages/Investment"); }); });
var NotFound = lazy(function () { return lazyRetry(function () { return import("@/pages/404Page"); }); });
var Portfolio = lazy(function () { return lazyRetry(function () { return import("@/pages/Portfolio"); }); });
var PortfolioOverview = lazy(function () { return lazyRetry(function () { return import("@/pages/PortfolioOverview"); }); });
var UIExamples = lazy(function () { return lazyRetry(function () { return import("@/pages/UIExamples"); }); });
export default (function () {
    return (React.createElement(Router, null,
        React.createElement(Suspense, { fallback: React.createElement("div", null) },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: Routes.HOME, component: Dashboard }),
                React.createElement(Route, { exact: true, path: Routes.LOGIN, component: Login }),
                React.createElement(Route, { exact: true, path: Routes.REGISTER, component: Register }),
                React.createElement(Route, { exact: true, path: Routes.SAVE_PASSWORD, component: SavePassword }),
                React.createElement(Route, { exact: true, path: Routes.PASSWORD_RESET, component: ResetPassword }),
                React.createElement(Route, { exact: true, path: Routes.PASSWORD_FORGOT, component: ForgotPassword }),
                React.createElement(Route, { exact: true, path: Routes.MY_ACCOUNT, component: MyAccount }),
                React.createElement(Route, { exact: true, path: Routes.MY_COMPANY, component: MyCompany }),
                React.createElement(Route, { exact: true, path: Routes.MY_COMPANY_PREFERENCES, component: MyCompany }),
                React.createElement(Route, { exact: true, path: Routes.REPORTS, component: Reports }),
                React.createElement(Route, { exact: true, path: Routes.BUSINESS_HUB, component: ResourceCenter }),
                React.createElement(Route, { exact: true, path: Routes.APPLICATION, component: Application }),
                React.createElement(Route, { exact: true, path: Routes.ONBOARDING, component: Onboarding }),
                React.createElement(Route, { exact: true, path: Routes.ONBOARDING_USER_PROFILE, component: OnboardingUserProfile }),
                React.createElement(Route, { exact: true, path: Routes.ONBOARDING_COMPANY_PROFILE, component: OnboardingCompanyProfile }),
                React.createElement(Route, { exact: true, path: Routes.INVESTMENT, component: Investment }),
                React.createElement(Route, { exact: true, path: Routes.PORTFOLIO, component: Portfolio }),
                React.createElement(Route, { exact: true, path: Routes.PORTFOLIO_OVERVIEW, component: PortfolioOverview }),
                React.createElement(Route, { exact: true, path: Routes.UI_EXAMPLES, component: UIExamples }),
                React.createElement(Route, { exact: true, path: "*", component: NotFound })))));
});
